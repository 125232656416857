import { useState, useReducer, useEffect } from 'react'
import { gsap } from 'gsap'

import { ThemeFileInputElement, ThemeTextFieldElement } from 'components/baseComponents';
import './App.css';

const processFile = (f: File) => {
    const formData = new FormData(); 
    formData.append('pdfFile', f)
    return fetch('https://personal.hugopinelo.com/extract-text', {
        method: 'post',
        body: formData
    }).then(response => {
        return response.text()
    })
}

type TExtractConfiguration = {
    line: number | null;
    pattern: string | null;
    name: string | null;
}

const onFileInputChange = async (files: FileList) => {
    const textArray: Array<string> = []

    for(let i = 0; i < files.length; i++ ) {
        const text = await processFile(files[i])
        textArray.push(text)
    }
    return textArray;
}

type TAction = {
    type: string;
    payload: any;
}

type TConfigurationState = {
    lines: Array<TExtractConfiguration>;
    text: Array<string>;
    active: boolean;
    name: string;
}

const configurationReducer = (state: TConfigurationState , action: TAction): TConfigurationState => {
    switch(action.type) {
        case "addLine": {
            const newState = {
                ...state,
                lines: [...state.lines, action.payload]
            }
            return newState;
        }
        case "removeLine": {
            return {
                ...state,
                lines: [...state.lines.filter(l => l.line != action.payload)]
            }
        }
        case "setLine": {
            let lines = [...state.lines]
            if(action.payload.name != null)
                lines = [...lines.filter((l: TExtractConfiguration) => l.name != action.payload.name && l.line != action.payload.line), action.payload]
            if(action.payload.pattern == '') 
                lines = [...lines.filter((l: TExtractConfiguration) => l.name != action.payload.name && l.line != action.payload.line)]
            return {
                ...state,
                lines
            }
        }
        case "setActive": {
            return {
                ...state,
                active: action.payload
            }
        }
        case "setText": {
            return {
                ...state,
                text: action.payload
            }
        }
        case "setConfiguration": {
            return {
                ...state,
                lines: action.payload.lines,
                name: action.payload.name
            }
        }
        case "setName": {
            return {
                ...state,
                name: action.payload
            }
        }
        default:
            return state;
    }
}

const isLineConfigured = ({idx, lines}: {idx: number, lines: TExtractConfiguration[]}) => (
    lines.some((e: TExtractConfiguration) => e.line == idx)
)

const patternFor = ({idx, lines}: {idx: number, lines: TExtractConfiguration[]}) => (
    lines.find((e: TExtractConfiguration) => e.line == idx)?.pattern || '.*'
)

const replaceString = (text: string, pattern: string) => {

    try {
        const match = text.match(new RegExp(pattern))
        if(match)
            debugger
        return match ? match[0] : '';
    } catch(e) {
        return text;
    }
}

const replace = (idx: number, text: string, state: TConfigurationState) => {
    const configurationLine = state.lines.find(e => e.line == idx )
    if(!configurationLine)
        return text;
    return replaceString(text, configurationLine.pattern || '.*')
}

const ExtractedTextLine = ({t, idx, state, dispatch}: {t: string, idx: number, state: TConfigurationState, dispatch: (arg0: TAction) => unknown}) => {
    const isActive = isLineConfigured({idx, lines: state.lines})
    const [configuring, setConfiguring] = useState(isActive)

    useEffect(() => {
        if(!configuring) {
            dispatch({type: 'removeLine', payload: idx})
        }
    }, [configuring]) 

    return (
    <div className="flex slow-float-up m-4"
         style={{cursor: 'pointer', maxWidth: '50em', boxShadow: isActive ? '0 3px 6px #000A' : '0 0 0 #0000' }} 
    >
        <div className={`mr-4 text-md p-4 text-left grow duration-300 transition-all`}
            onClick={() => {setConfiguring(!configuring)}}>
            { isActive ? replace(idx, t, state): t}
        </div>

        {configuring && (
            <div className="bg-gray-100 border-b-1 border-slate-200 text-md grow-0 flex items-center p-3 ">
                <input onChange={(e) => {
                    const text = e.target.value
                    const name = text.substring(0, text.indexOf(':'))
                    const pattern = text.substring(text.indexOf(':')+1)

                    dispatch({type: 'setLine', payload: {line: idx, pattern: `${pattern}`, name}})
                }} className='bg-transparent outline-none' type="text"/>
            </div>

        )}
        </div>
    )
}

const FileConfigurationComponent = ({state, dispatch}: {state: TConfigurationState, dispatch: (arg0: TAction) => unknown}) => {

    useEffect(() => {
        gsap.fromTo('.slow-float-up', {opacity: 0, y: 20}, {opacity: 1, y: 0, duration: 0.3, stagger: 0.025})
    }, [state.text])

    return (
        <div className='flex flex-col my-8 '>
            <div className="p-8 bg-white shadow-md flex flex-col">
                {state.text.map((t: string) => (
                    t.split('\n').filter((t: string) => t != '').map((t: string, idx: number) => (
                        <ExtractedTextLine idx={idx} t={t} state={state} dispatch={dispatch}/>
                    ))
                ))}
            </div>


        </div>

    )
}
const parseText = (pdfText: string, config: TConfigurationState): any => {
    const text = pdfText.split('\n').filter((t: string) => t != '')
    const obj: any = {}
    config.lines.forEach(l => {
        const name = l.name
        const lineNumber = l.line
        const pattern = l.pattern || '.*'
        if(name == null || pattern == null || lineNumber == null)
            return

        obj[name] = replaceString(text[lineNumber], l.pattern || '.*')
    })
    return obj;
}

const ConfigurationInspector = ({config}: {config: TConfigurationState}) => {
    return <>

        {
            config.lines.map(l => {
                const text = config.text[0].split('\n').filter((t: string) => t != '')[l.line || 0]
                return <div className="flex items-center my-2">
                    <div className="text-md p-2 bg-slate-200 rounded-md mr-2">{l.name}:</div>
                    <div className="text-md p-2 rounded-md">{replaceString(text, l.pattern || '.*')}</div>
                </div>
            })
        }


      {config.lines.length > 0 && (
            <button className='w-full mt-4 py-2 px-6 bg-blue-500 text-white rounded-md' onClick={() => {
                saveConfiguration(config);
            }}>Guardar Configuracion</button>
      )}
    </>

}

const ConfigurationView = ({state, dispatch, availableConfigurations}: {state: TConfigurationState, dispatch: (arg0: TAction) => unknown, availableConfigurations: TConfigurationState[]}) => {
    return (
        <div className="container mx-auto mt-8">
        <div className="flex flex-col bg-white shadow-md p-12 w-min" style={{minWidth: '50vw'}}>
            <div className="text-3xl text-zinc-700 text-left float-up">Configuracion</div>
            <div className="w-80 mb-4 mt-8 float-up">
                <ThemeTextFieldElement
                    name='name'
                    label='Nombre de configuracion'
                    value={state.name}
                    onChange={e => {
                        dispatch({type: 'setName', payload: e.target.value})
                    }}
                />
            </div>
            <div className="w-80 float-up">
                <ThemeFileInputElement label='test' name='files' onChange={async (files: FileList) => {
                   const text = await onFileInputChange(files);
                   dispatch({type: 'setText', payload: text})
                }}/>
            </div>


      {state.active && state.text.length > 0 && <FileConfigurationComponent state={state} dispatch={dispatch}/>}
        </div>





      </div>
    )

}

const InfoWindow = ({state, dispatch, availableConfigurations}: {state: TConfigurationState, dispatch: (arg0: TAction) => unknown, availableConfigurations: TConfigurationState[]}) => {
    return (
            <div className="fixed right-4 top-8">
                
              {availableConfigurations.length > 0 && (
                 <div className="shadow-md p-10 my-5 flex flex-col bg-white">
                    <div className="text-md text-slate-600 mb-6">Configuraciones Guardadas</div>
                    {availableConfigurations.map(a => {
                        const isSelectedConfig = state.name == a.name
                        return <button 
                            className={` ${isSelectedConfig ? 'bg-blue-500 text-white' : 'bg-white border border-1 border-blue-500 text-blue-500'} rounded-md my-1  py-2 px-6 `}
                            key={a.name} 
                            onClick={() => dispatch({type: 'setConfiguration', payload: a})}>{a.name}</button>
                    })}
                </div>
              )}


                {state.text.length > 0 && state.active && (
                        <div className="shadow-md p-10 bg-white">
                           <ConfigurationInspector config={state}/>
                        </div>
                )}
            </div>
    )

}


const SideBar = ({dispatch}: {dispatch: (arg0: TAction) => unknown}) => {
    return (
    
        <div id='sidebar' className={`justify-between group z-20
            fixed left-0 top-0 h-full flex flex-col bg-white border-[1px]
            `}>
            
        <div className='flex flex-col'>
                <div className="theme__link" onClick={() => {
                    dispatch({type: 'setActive', payload: true})
                }}>
                    <span className="material-symbols-outlined"> settings </span>
                    <div className="theme__link-text">Configurar</div>
                </div>
                <div className="theme__link" onClick={() => {
                    dispatch({type: 'setActive', payload: false})
                }}>
                    <span className="material-symbols-outlined"> inventory </span>
                    <div className="theme__link-text">Extraer</div>
                </div>
            </div>
        </div>
    )
}

const ExtractionView = ({state, dispatch, availableConfigurations}: {state: TConfigurationState, dispatch: (arg0: TAction) => unknown, availableConfigurations: TConfigurationState[]}) => {

    useEffect(() => {
        const t1 = gsap.timeline()
        t1.fromTo('#sep-border', {width: 0, opacity: 0}, {width: '100%', opacity: 1})
        .fromTo('#copy-icon', {opacity: 0}, {opacity: 1})
    }, [state.active, state.text])

    return (
        <div className="container mx-auto mt-8">
            <div className="flex flex-col bg-white shadow-md p-12 w-min" style={{minWidth: '50vw'}}>
                        <div className="text-3xl text-zinc-700 text-left float-up">Extraccion</div>

                        <div className="w-80 float-up mt-3">
                            <ThemeFileInputElement label='test' name='files' onChange={async (files: FileList) => {
                               const text = await onFileInputChange(files);
                               dispatch({type: 'setText', payload: text})
                            }}/>
                        </div>

                    {state.text.length > 0 &&(
                        <>
                        <span id='copy-icon' className="material-symbols-outlined self-end m-4" onClick={() => {
                            const text = state.text.reduce((acc, t) => {
                                const extractedObject = parseText(t, state)
                                Object.keys(extractedObject).forEach(k => {
                                    acc+=extractedObject[k]+','
                                })
                                acc = acc.substring(0, acc.length-1) + '\n'
                                return acc;
                            }, `${state.lines.map(l => l.name).join(',')}\n`)
                            navigator.clipboard.writeText(text)

                        }}>content_copy</span>
                        <div id='sep-border' className="border-b border-b-1 border-slate-800 w-full"></div>
                        </>
                    )}

                        <div className="flex flex-wrap mt-10 float-up">
                            {state.text.map((t: string) => {
                                const extractedObject = parseText(t, state)
                                const keys = Object.keys(extractedObject)
                                return (
                                    <div className="shadow-md bg-white p-8 flex flex-col mr-10">
                                        {keys.map(k => (
                                            <div className='flex items-center'>
                                                <div className="text-md font-bold mr-2">{k}:</div>
                                                <div className="text-md">{extractedObject[k]}</div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            })}
                        </div>
            </div>
        </div>
    )

}

const saveConfiguration = (config: TConfigurationState) => {
    const configurations = JSON.parse(localStorage.getItem('config') || '[]') as TConfigurationState[]
    localStorage.setItem('config', JSON.stringify([...configurations.filter(c => c.name != config.name), config]))
}


function App() {
    // const [isConfiguration, setIsConfiguration] = useState<boolean>(false)
    const [state, dispatch] = useReducer(configurationReducer, {active: false, lines: [], text: [], name: ''})
    const [availableConfigurations, setAvailableConfigurations] = useState<TConfigurationState[]>([])
    const [activeView, setView] = useState('configure')

    useEffect(() => {
        const configurations = JSON.parse(localStorage.getItem('config') || '[]')
        setAvailableConfigurations(configurations);
        if(configurations.length > 0)
            dispatch({type: "setConfiguration", payload: configurations[0]})
        else dispatch({type: 'setActive', payload: true})
    }, [])

    useEffect(() => {
        gsap.fromTo('.float-up', {opacity: 0, y: 20}, {opacity: 1, y: 0, duration: 0.3, stagger: 0.1, delay: 0.6})
    }, [state.active])


  return (
    <div className="App">
      <SideBar dispatch={dispatch}/>
      <div className="pl-20">
          {state.active && <ConfigurationView state={state} dispatch={dispatch} availableConfigurations={availableConfigurations}/>}
          {!state.active && <ExtractionView state={state} dispatch={dispatch} availableConfigurations={availableConfigurations}/>}
      </div>
      <InfoWindow state={state} dispatch={dispatch} availableConfigurations={availableConfigurations}/>
    </div>
  );
}

export default App;
